<template>
  <div>
    <x-header>
      <img
        slot="headerImage"
        src="../../../assets/zhongoubanliejingwaifuwushang/banner.jpg"
        alt=""
      />
      <h1 slot="headerTitle">{{$store.state.navShow == true ? '中欧班列境外服务商':'Overseas service provider of China-Europe Railway Express'}}</h1>
    </x-header>

    <div class="main">
      <p>
       {{$store.state.navShow == true ? ' MG集团起源于俄罗斯圣彼得堡，在欧洲以及俄罗斯，中亚等地区建立了完善的自营服务网点和代理网络，以中欧班列境外(车板、铁路承运)业务优势与中国“—带—路”形成无缝衔接，主要提供往返于亚洲-欧洲的班列运输和综合物流服务，致力于为客户提供极具竞争力的综合物流解决物流方案。':'MG Group originated in St. Petersburg, Russia. As the carrier of the overseas section of the China-Europe freight train, MG Group has established an international transportation network and port agency points with the Eurasian railway and the CIS countries and regions based on the railways of Russia as the main country, providing European and American railways. A round-trip container shipping service. It has long-term cooperation with Russia and Eurasian national railways, transportation capacity and departure plan, and has self-operated resources in container transfer port reloading, car board resources, railway yards, automobile land transportation, customs clearance and distribution. The professional overseas operation team can integrate the advantageous railway resources of the countries in the overseas section, and provide customers with the most cost-effective and stable overseas section train transportation solutions.'}}
      </p>

      <div class="sign_img">
        <img
          v-if="$store.state.navShow == true"
          src="../../../assets/zhongoubanliejingwaifuwushang/yunlidiaodong.jpg"
          alt=""
        /><img
          v-else
          src="../../../assets/zhongoubanliejingwaifuwushang/yunlidiaodongE.jpg"
          alt=""
        />
      </div>
    </div>

    <div class="mainBigImg">
      <!-- 中欧班里 -->
      <div class="mainContent">
        <p>{{$store.state.navShow == true ?'中欧班列境外服务商':'Overseas service providers of China Railway Express'}}</p>
        <span>{{$store.state.navShow == true ?'作为中欧班列境外服务商，MG集团有着丰富的班列操作经验，我们整合各个国家（白俄铁路，波兰铁路，俄罗斯铁路，哈萨克斯坦铁路，外蒙古铁路）最优势的一部分，从而能实现跨境运输高效对接，确保整体运输方案的安全、高效、可靠。我们能整合境外段国家的铁路优势资源为客户提供性价比最高和稳定的境外段班列运输解决方案。':'As an overseas service provider of China-Europe Railway, MG Group has rich experience in operating trains. We can take the most advantageous part of the country (Belarus Railway, Poland Railway, Russian Railway, Kazakhstan Railway, and Outer Mongolia Railway), so as to achieve Cross-border transport universities are connected to ensure the safety, efficiency and reliability of the overall transport scheme. We can integrate the advantageous railway resources of overseas countries to provide customers with the most cost-effective and stable overseas train transportation solutions.'}}</span>

        <div class="mapSign">
          <img v-if="$store.state.navShow == true" src="../../../assets/zhongoubanliejingwaifuwushang/mapSign.jpg" alt="">
          <img v-if="$store.state.navShow == false" src="../../../assets/zhongoubanliejingwaifuwushang/mapSignE.jpg" alt="">
        </div>

        <div class="showImg">
          <div class="showImg_l" data-aos="fade-right">
            <img src="../../../assets/zhongoubanliejingwaifuwushang/01.jpg" alt="" >
          </div>
          <div class="showImg_r"  data-aos="fade-left">
              <img src="../../../assets/zhongoubanliejingwaifuwushang/02.jpg" alt="">
              <img src="../../../assets/zhongoubanliejingwaifuwushang/03.jpg" alt="">
          </div>
        </div>
      </div>

      <img src="../../../assets/zhongoubanliejingwaifuwushang/bgxuying.png" alt="">

    </div>

    <div class="footer_img">
      <img src="../../../assets/pages_bottom_img.png" alt="" />
    </div>

  </div>
</template>
<script>
// 头尾导入
import xHeader from '../../../components/header.vue'
export default {
  components: {
    // 使用/注册引入的组件 components
    xHeader
  },
  data () {
    return {}
  },
  methods: {
    /**
     * 相关业务点击事件
     * 肖明霞
     * 2021-11-22
     */
    relateClick (jumpUrl) {
      this.$router.push({
        path: '/ownJumpPage',
        query: {
          pathUrl: jumpUrl
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.main {
  width: var(--width1200px);
  margin: 110px auto 0;
  // background: pink;
  text-align: left;
  & > p {
    text-indent: 32px;
    font-family: Microsoft YaHei;
    color: #666666;
    line-height: 35px;
  }
  .sign_img {
    margin-top: 60px;
  }
}
.mainBigImg {
  width: 100%;
  position: relative;
  // background: red;
  .mainContent {
    width: 1200px;
    margin: 0 auto 47px;
    // background: orange;
    & > p {
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #2e67b1;
      text-align: left;
      margin: 66px 0 42px 0;
    }
    & > span{
       display: inline-block;
       text-indent: 32px;
       font-family: Microsoft YaHei;
       color: #666666;
       line-height: 35px;
       text-align: left;
    }
    & > .mapSign{
      margin-top: 25px;
    }
    & > .showImg{
      width: 100%;
      height: 500px;
      // background: red;
      margin-top: 85px;
      display: flex;
      justify-content: space-between;
      .showImg_l{
        width: 750px;
        // background: green;
      }
       .showImg_r{
        width: 420px;
        // background: pink;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }
  }
  & > img{
    position: absolute;
    top:-200px;
    left: 0;
    z-index:-1;
  }
}
</style>
